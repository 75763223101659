import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedAuthDataService {
  private shared$: BehaviorSubject<Boolean | any> = new BehaviorSubject<
    Boolean | any
  >(null);

  private _userData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  readonly userData$: Observable<any> = this._userData$.asObservable();

  setUserData(data: any) {
    this._userData$.next(data);
  }
}
