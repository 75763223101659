import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from './shared/guards/authenticated.guard';
import { DisabledGuard } from './core/auth/guards/disabled.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./core/pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'home',
    redirectTo: '',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./core/auth/authenticate/authenticate.module').then(
        (m) => m.AuthenticateModule
      ),
    canActivate: [DisabledGuard],
  },
  {
    path: 'sobre-nos',
    loadChildren: () =>
      import('./core/pages/about-us/about-us.module').then(
        (m) => m.AboutUsModule
      ),
  },
  {
    path: 'servicos',
    loadChildren: () =>
      import('./core/pages/services/services.module').then(
        (m) => m.ServicesModule
      ),
  },
  {
    path: 'meu-perfil',
    loadChildren: () =>
      import('./core/pages/user-profile/user-profile.module').then(
        (m) => m.UserProfileModule
      ),
    canActivate: [AuthenticatedGuard],
  },
  {
    path: 'fornecedor/:id',
    loadChildren: () =>
      import('./core/pages/providers/providers.module').then(
        (m) => m.ProvidersModule
      ),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
