import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ServicesService } from 'src/app/shared/services/services.service';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss']
})

export class ProvidersComponent implements OnInit  {
  public subscriptions: Subscription[] = []

  public idUser: number;

  public userData: any;

  public integracionUp: boolean = false;

  constructor(
    private serviceProvider: ServicesService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.getParamsUser();
    this.getProviders();
  }

  getParamsUser() {
    this.subscriptions.push(
       this.activatedRoute.params.subscribe(response => {
          this.idUser = response.id
       }),
    )
 }

  /* istanbul ignore next */
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  getProviders() {
    this.subscriptions.push(
      this.serviceProvider.getProvider(this.idUser).subscribe({
        next: (response: any) => {
          this.userData = response;
        },
        error: error => {
          console.error(error);
        }
      })
    )
  }

}
