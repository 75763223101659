<div class="card">
  <div class="card__photo">
    <img [src]="imageDefault">
  </div>

  <div class="card__content">
    <div class="texts">
      <h2>{{eventInfo?.name}}</h2>
      <div class="description">{{ eventInfo?.description }}</div>
    </div>

    <a href="fornecedor/{{ eventInfo?.id }}">Saiba mais<img src="assets/img/icons/right_arrow.png"></a>
  </div>
</div>
