import { Component } from '@angular/core';
import { GaService } from '../services/ga.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from './components/services/dialog/dialog.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent {
  constructor(
    private ga: GaService,
    private _dialog: MatDialog,
  ) {}

  dispatchEvent() {
    this.ga.pushEvent('link_click', {
      label: 'servicos',
      pageName: 'meu-perfil',
      segment: 'area-logada',
      page: 'logado',
      section: 'aplicacao',
    });
    this.openDialog();
  }

  // Abre modal ao acessar a página
  openDialog(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.direction = 'ltr';
    dialogConfig.closeOnNavigation = true;
    this._dialog.open(DialogComponent, dialogConfig);
  }
}
