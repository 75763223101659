import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-terms-services',
  templateUrl: './terms-services.component.html',
  styleUrls: ['./terms-services.component.scss'],
})
export class TermsServicesComponent {
  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  closeDialog(emit: boolean) {
    this.closeModal.emit(emit);
  }
}
