import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GaService } from 'src/app/core/pages/services/ga.service';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  constructor(private dialog: MatDialog, private router: Router, private ga: GaService) {}
  ngOnInit(): void {}

  closeDialog(): void {
    this.dialog.closeAll();
    this.ga.pushEvent('modal',{
      label: 'mudar-agora',
      segment: 'area-logada',
      page: 'logado',
      section: 'aplicacao',
      pageName: 'meu-perfil/servicos'
    })
  }

  dispatchEvent() {
    this.ga.pushEvent('modal',{
      label: 'politica-de-privacidade',
      segment: 'area-logada',
      page: 'logado',
      section: 'aplicacao',
    })
  }

  redirect(): void {
    this.dialog.closeAll();
    this.router.navigate(['/meu-perfil']);
    this.ga.pushEvent('modal',{
      label: 'cancelar',
      pageName: 'meu-perfil/servicos',
      segment: 'area-logada',
      page: 'logado',
      section: 'aplicacao',
    })
  }
}
