import { Component, OnInit } from '@angular/core';
import { GaService } from 'src/app/core/pages/services/ga.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {

  constructor(private ga: GaService) {}

  dispatchEvent(labelName: string, segment) {
    this.ga.pushEvent('link_click', { 
      label: labelName, 
      category: 'rodape', 
      segment: segment ? 'menu' : 'whatsapp',
      page: 'home',
      section: 'aplicacao'
    })
  }

}
