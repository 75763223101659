import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  constructor(private http: HttpClient) { }

  submit(data): Observable<any> {
    return this.http.post<any>(`${API}services`, data);
  }

  getProvider(id: Number): Observable<Number> {
    return this.http.get<Number>(`${API}services/${id}`);
  }

  getService(): Observable<any> {
    return this.http.get<any>(`${API}services/home`);
  }

  getUserService(): Observable<any> {
    return this.http.get<any>(`${API}services/user`)
  }
}
