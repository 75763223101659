<p-toast></p-toast>

<div class="services">
  <div class="services__header">
    <h3>Serviços</h3>
    <p>
      Todos os campos marcados com * são obrigatórios. Para editar um serviço
      existente, entre em contato com nossa equipe.
    </p>
  </div>
  <form
    [formGroup]="serviceForm"
    class="services__form"
    *ngIf="isFormShowing"
    (ngSubmit)="submit()"
  >
    <div class="left-side">
      <select formControlName="category" name="tipo" id="category">
        <option value="" selected disabled hidden>Tipos de serviço*</option>
        <option *ngFor="let category of categories" [value]="category.id">
          {{ category.name | titlecase }}
        </option>
      </select>
      <input
        formControlName="name"
        type="text"
        placeholder="Nome do serviço *"
      />
      <p-inputMask
        [readonly]="hasData"
        styleClass="input-mask"
        formControlName="phone"
        placeholder="Telefone *"
        mask="(99) 9 9999-9999"
      ></p-inputMask>
      <input formControlName="website" type="text" placeholder="Site" />
      <span class="invalid"> {{ serviceForm.website }} </span>
      <textarea
        formControlName="description"
        maxlength="200"
        name="descricao"
        placeholder="Descrição do serviço"
      ></textarea>
      <div class="buttons">
        <button type="submit" [disabled]="serviceForm.invalid" class="bt-save">
          Salvar informações
        </button>
      </div>
    </div>

    <div class="right-side">
      <div class="upload-img">
        <p-fileUpload
          mode="basic"
          name="files[]"
          [customUpload]="true"
          [auto]="true"
          (uploadHandler)="onUpload($event)"
          accept="image/*"
          maxFileSize="1000000"
          multiple="multiple"
        ></p-fileUpload>
        <div>
          <span class="green-letter">Clique aqui para adicionar</span> uma foto
          do seu serviço(.png, .jpg)
        </div>
        <div style="font-size: 0.7rem" *ngFor="let file of collection">
          <span>{{ file.name }} - {{ file.size }} bytes</span>
        </div>
      </div>
      <button class="bt-cancel" (click)="showForm()">Cancelar</button>
    </div>
  </form>

  <div class="services__item">
    <div *ngIf="!isFormShowing">
      <div *ngFor="let service of userServices">
        <app-event-provider [eventInfo]="service"></app-event-provider>
      </div>
    </div>

    <div class="add-form" *ngIf="!isFormShowing" (click)="showForm()">
      <img src="assets/img/profile/services/plus.svg" alt="adicionar" />
      <div>
        <span class="green-letter">Clique aqui para adicionar</span> um serviço
      </div>
    </div>
  </div>
</div>
