import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const API = environment.apiUrl;
@Injectable({
  providedIn: 'root',
})
export class DataUserService {
  constructor(private http: HttpClient) {}

  getDataUser(): Observable<any> {
    return this.http.get<any>(`${API}users/profile`);
  }
}
