import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GaService {
  public pushEvent(event: string, data: any = {}): void {
    if (typeof window !== 'undefined') {
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({ event, ...data });
    }
  }
}
