import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  readonly api: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public uploadFiles(data: any, type: string) {
    const headers = new HttpHeaders();
    return this.http.post<any>(`${this.api}upload?type=${type}`, data);
  }
}
