<div class="supplier-header">
  <!-- <div class="supplier-header__path">
    <span>Fornecedores</span>
    <span><img src="../../../../../../assets/img/icons/path-pink-arrow.svg" alt=""></span>
    <span>Fotografia</span>
    <span><img src="../../../../../../assets/img/icons/path-pink-arrow.svg" alt=""></span>
    <span>Nome do Fornecedor</span>
  </div> -->
  <div class="supplier-header__photos">
    <img
      class="img-left"
      [src]="imgCtaDefault.serviceMedia[0].url"
      alt="Fornecedor"
    />
    <img
      class="img-right"
      [src]="imgCtaDefault.serviceMedia[0].url"
      alt="Fornecedor"
    />
  </div>
</div>
