import { SharedAuthDataService } from './../services/shared-auth-data.service';
import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ELocalStorage } from 'src/app/core/auth/authenticate/enums/localstorage.num';
import { DataUserService } from '../services/data-user.service';
import { GaService } from 'src/app/core/pages/services/ga.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('500ms', style({ transform: 'translateY(-100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit, OnChanges {
  public sidebar = false;
  public isLogged = false;
  public dropdownOpen: boolean;
  public isAuthenticated: boolean;
  public userData: any;

  constructor(
    private dataUserService: DataUserService,
    private sharedAuthDataService: SharedAuthDataService,
    private ga: GaService,
    private router: Router
  ) {}
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {
    this.verifyUserLoggin();
  }

  dispatchEvent(path: string, gaEvent: object) {
    this.ga.pushEvent(
      'genericEvent',
      Object.assign(
        {
          eventName: '',
          parameter1: '',
          parameter2: '',
          parameter3: '',
          parameter4: '',
          parameter5: '',
        },
        gaEvent
      )
    );
    this.router.navigate([path]);
  }

  verifyUserLoggin() {
    let savedToken: string | null | undefined;

    savedToken = sessionStorage.getItem(ELocalStorage.USER_TOKEN);

    if (savedToken) {
      this.isAuthenticated = true;
      this.getProfileData();
    } else {
      this.isAuthenticated = false;
    }
  }

  getProfileData() {
    this.dataUserService.getDataUser().subscribe({
      next: (profile) => {
        this.userData = profile;
        sessionStorage.setItem('profile', JSON.stringify(profile));
        this.sharedAuthDataService.setUserData(profile);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  toggleMenu(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  logout(): void {
    sessionStorage.removeItem(ELocalStorage.USER_TOKEN);
    location.reload();
  }

  toggleSidebar() {
    this.sidebar = !this.sidebar;
  }
}
