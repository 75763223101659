<section class="search-panel">
  <div class="search-panel__bar-side">
    <h1>Contrate Fornecedores, Serviços, Espaços</h1>
    <p>E faça sua festa acontecer</p>
    <div class="search-bar">
      <img
        src="../../../assets/img/icons/search-icon.png"
        alt=""
        height="20"
        width="20"
      />
      <div class="form">
        <input
          type="search"
          name=""
          id=""
          placeholder="Procurar serviços, Fornecedores e espaços"
          [(ngModel)]="valueSearch"
        />
        <button
          type="submit"
          (click)="dispatchEvent()"
          [disabled]="!valueSearch"
        >
          PESQUISAR
        </button>
      </div>
    </div>
  </div>
  <div class="search-panel__photo">
    <img
      rel="preload"
      as="image"
      src="assets/img/home/party.jpeg"
      alt="imagem de pessoas em uma festa"
    />
  </div>
</section>
