import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-supplier-header',
  templateUrl: './supplier-header.component.html',
  styleUrls: ['./supplier-header.component.scss']
})
export class SupplierHeaderComponent {
  @Input() public imgCtaDefault: any
}
