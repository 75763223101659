import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ELocalStorage } from 'src/app/core/auth/authenticate/enums/localstorage.num';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let itemStr: any = sessionStorage.getItem(ELocalStorage.USER_TOKEN);

    if (itemStr) {
      return true;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item?.expiry || !item?.expiry) {
      sessionStorage.clear();
      this.router.navigate(['']);
      return false;
    }
    // let userData: any;

    // userData = JSON.parse(
    //   localStorage.getItem(ELocalStorage.USER_DATA) || '{}'
    // );
    // if (!Object.keys(userData)?.length) {
    //   this.router.navigate(['']);
    //   return false;
    // } else {
    //   return true;
    // }

    return false;
  }
}
