import { Component, Input, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],

  animations: [
    trigger('openAnimation', [
      state('open', style({ height: '*', opacity: 1 })),
      state('closed', style({ height: '0', opacity: 0 })),
      transition('closed => open', [
        style({ height: '0', opacity: 0 }),
        animate('1s ease-in-out', style({ height: '*', opacity: 1 })),
      ]),
      transition('open => closed', [
        style({ height: '*', opacity: 1 }),
        animate('1s ease-in-out', style({ height: '0', opacity: 0 })),
      ]),
    ]),
  ],
})
export class AccordionComponent {
  @Input() title: string;
  @Input() isOpen: boolean;
}
