<div class="change-password">
  <div class="change-password__header">
    <h3>Mudar senha</h3>
    <p>Todos os campos marcados com * são obrigatórios</p>
  </div>
  <form class="change-password__form" [formGroup]="changePassForm">
    <div class="change-password__form-inputs">
      <input
        type="text"
        placeholder="Senha atual *"
        formControlName="currentPass"
      />
      <input
        type="text"
        placeholder="Nova senha * "
        formControlName="newPass"
      />
      <input
        type="number"
        placeholder="Confirmar nova senha *"
        formControlName="confirmPass"
      />
    </div>
    <div class="buttons">
      <button class="save-information">Salvar informações</button>
      <button class="cancel">Cancelar</button>
    </div>
  </form>
</div>
