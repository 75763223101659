import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const API = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class RegisterDataService {
  constructor(private http: HttpClient) {}

  registerData(data): Observable<any> {
    return this.http.post<any>(`${API}users/info`, data);
  }
}
