<div class="documents">
  <div class="documents__header">
    <h3>Documentos</h3>
    <p>Todos os campos marcados com * são obrigatórios</p>
  </div>
  <form class="documents__form" [formGroup]="documentForm">
    <div class="documents__form-inputs">
      <input
        type="text"
        placeholder="CPF *"
        name="cpf"
        required
        id="cpf"
        maxlength="14"
        formControlName="cpf"
      />
      <input type="text" placeholder="CNPJ" formControlName="cnpj" />
    </div>
    <div class="buttons">
      <button class="save-information">Salvar informações</button>
      <button class="cancel">Cancelar</button>
    </div>
  </form>
</div>
