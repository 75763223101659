<div class="cards">
  <div class="cards__title">
    <span></span>
    <h1 class="cards__title-text">{{ providerImg?.name }}</h1>
  </div>
  <div class="cards__carousel">
    <div class="carousel-buttons">
      <button
        class="carousel-buttons__btn"
        (click)="navigation('prev')"
        [disabled]="disablePrevButton"
      >
        <img
          class="carousel-buttons__btn--prev"
          src="assets/img/icons/profile/arrow-down.svg"
          alt=""
        />
      </button>
      <button
        class="carousel-buttons__btn"
        (click)="navigation('next')"
        [disabled]="disableNextButton"
      >
        <img src="assets/img/icons/profile/arrow-down.svg" alt="" />
      </button>
    </div>
    <p-carousel
      #carousel
      [value]="providerImg?.serviceMedia"
      [numVisible]="4"
      [numScroll]="2"
      [responsiveOptions]="responsiveOptions"
      [showIndicators]="false"
      [page]="page"
      [showNavigators]="false"
    >
      <ng-template let-card pTemplate="item">
        <app-cards [eventInfo]="card"></app-cards>
      </ng-template>
    </p-carousel>
  </div>
</div>
