import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { AddressComponent } from './components/address/address.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserProfileComponent } from './user-profile.component';
import { PersonalDataComponent } from './components/personal-data/personal-data.component';
import { ServicesComponent } from './components/services/services.component';

const routes: Routes = [
  {
    path: '',
    component: UserProfileComponent,
    children: [
      { path: '', component: PersonalDataComponent },
      // Habilitar quando tiver os outros endpoints
      // { path: 'endereco', component: AddressComponent },
      // { path: 'documentos', component: DocumentsComponent },
      // { path: 'mudar-senha', component: ChangePasswordComponent },
      { path: 'servicos', component: ServicesComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserProfileRoutingModule {}
