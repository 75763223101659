import { Component, Input, ViewChild } from '@angular/core';
import { ICategory } from './../../../../../shared/interfaces/ICategories';

@Component({
  selector: 'app-providers-cards',
  templateUrl: './providers-cards.component.html',
  styleUrls: ['./providers-cards.component.scss'],
})
export class ProvidersCardsComponent {
  @ViewChild('carousel') prevBtn: any;
  @Input() providerImg: any;

  responsiveOptions: any;
  public page = 0;
  public disablePrevButton: boolean = true;
  public disableNextButton: boolean = false;
  public categoriesList: ICategory | any;

  constructor() {
    this.responsiveOptions = [
      {
        breakpoint: '1200px',
        numVisible: 3,
        numScroll: 2,
      },
      {
        breakpoint: '900px',
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 2,
      },
    ];
  }

  navigation(type: 'prev' | 'next') {
    this.page = type === 'prev' ? this.page - 1 : this.page + 1;
    this.mathPage();
  }

  private mathPage() {
    setTimeout(() => {
      const lengthPage = Math.floor(
        this.categoriesList.length / this.prevBtn.prevState.numScroll - 1
      );
      this.disableNextButton = this.page >= lengthPage || lengthPage === 1;
      this.disablePrevButton = this.page === 0;
    }, 200);
  }
}
