import { SharedAuthDataService } from './../../../../../shared/services/shared-auth-data.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterDataService } from '../../service/register-data.service';
import { MessageService } from 'primeng/api';
import { GaService } from '../../../services/ga.service';
@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss'],
  providers: [MessageService],
})
export class PersonalDataComponent implements OnInit {
  public registerDataForm: FormGroup;
  public validateUserInfo: any;
  public hasData = false;

  constructor(
    private fb: FormBuilder,
    private registerDataService: RegisterDataService,
    private messageService: MessageService,
    private sharedAuthDataService: SharedAuthDataService,
    private ga: GaService
  ) {}

  ngOnInit(): void {
    this.sharedAuthDataService.userData$.subscribe((data) => {
      if (data) {
        this.validateUserInfo = data;
        this.setValue(data);
        if (data?.name && data?.bornDate && data?.cellphone && data?.email) {
          this.hasData = true;
        }
      }
    });

    this.validateForm();
  }

  setValue(value: any) {
    this.registerDataForm.patchValue({
      name: value?.name || '',
      bornDate: value?.bornDate || '',
      cellphone: value?.cellphone || '',
      email: value?.email || '',
    });
  }

  private validateForm() {
    this.registerDataForm = this.fb.group({
      name: [
        this.validateUserInfo?.name || '',
        Validators.compose([Validators.required]),
      ],
      bornDate: [
        this.validateUserInfo?.bornDate || '',
        Validators.compose([Validators.required]),
      ],
      cellphone: [
        this.validateUserInfo?.cellphone || '',
        Validators.compose([Validators.required]),
      ],
      email: [
        this.validateUserInfo?.email || '',
        Validators.compose([Validators.required]),
      ],
    });
  }

  submitUserData() {
    this.ga.pushEvent('button_click', {
      label: 'salvar-informacoes',
      pageName: 'meu-perfil',
      segment: 'area-logada',
      page: 'logado',
      section: 'aplicacao',
    });
    if (this.registerDataForm.valid) {
      const payload = {
        name: this.registerDataForm.get('name')?.value,
        bornDate: this.registerDataForm.get('bornDate')?.value,
        cellphone: this.registerDataForm
          .get('cellphone')
          ?.value?.replace(/\D/g, ''),
        email: this.registerDataForm.get('email')?.value,
      };
      this.registerDataService.registerData(payload).subscribe({
        next: () => {
          this.registerDataForm.reset();
          this.showSuccess();
          this.registerDataForm.disable();
          this.hasData = true;
          this.ga.pushEvent('sucesso', {
            title: 'dados-pessoais-cadastrados.',
            pageName: 'meu-perfil',
            segment: 'area-logada',
            page: 'logado',
            section: 'aplicacao',
          });
        },
        error: (err) => {
          this.showError();
          this.registerDataForm.reset();
          console.error(err);
          this.ga.pushEvent('erro', {
            title: 'problema-no-servidor-tente-mais-tarde',
            pageName: 'meu-perfil',
            segment: 'area-logada',
            page: 'logado',
            section: 'aplicacao',
          });
        },
      });
    }
  }

  // ToastConfigs
  showError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Erro',
      detail: 'Problema no servidor, tente mais tarde.',
    });
  }

  showSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Dados pessoais cadastrados.',
    });
  }
}
