import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('carousel') prevBtn: any;

  public dataCarousel = [
    {
      id: 1,
      name: 'fotografia',
      displayName: 'Fotografia',
      display: true,
      displayOnHome: true,
      icon: 'https://borafestejar.com.br/assets/img/icons/categories/fotografia.png',
      createdAt: '2022-09-10T17:18:39.456Z',
      updatedAt: '2024-01-25T19:43:56.723Z',
      deletedAt: null,
    },
    {
      id: 2,
      name: 'drinks',
      displayName: 'Drinks',
      display: true,
      displayOnHome: true,
      icon: 'https://borafestejar.com.br/assets/img/icons/categories/drinks.png',
      createdAt: '2022-09-10T17:18:39.456Z',
      updatedAt: '2024-01-25T19:43:57.103Z',
      deletedAt: null,
    },
    {
      id: 3,
      name: 'espacos',
      displayName: 'Espaços',
      display: true,
      displayOnHome: true,
      icon: 'https://borafestejar.com.br/assets/img/icons/categories/espacos.png',
      createdAt: '2022-09-10T17:18:39.456Z',
      updatedAt: '2024-01-25T19:43:57.396Z',
      deletedAt: null,
    },
    {
      id: 4,
      name: 'comidas',
      displayName: 'Comidas',
      display: true,
      displayOnHome: true,
      icon: 'https://borafestejar.com.br/assets/img/icons/categories/comidas.png',
      createdAt: '2022-09-10T17:18:39.456Z',
      updatedAt: '2024-01-25T19:43:57.687Z',
      deletedAt: null,
    },
    {
      id: 1000000,
      name: 'outros',
      displayName: 'Outros',
      display: false,
      displayOnHome: false,
      icon: '',
      createdAt: '2022-09-10T17:18:39.456Z',
      updatedAt: '2022-09-10T17:18:39.456Z',
      deletedAt: null,
    },
  ];
  @Input() public options: any;

  @Output() public dispatchEvent: any = new EventEmitter();

  public page = 0;
  public disablePrevButton: boolean = true;
  public disableNextButton: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.mathPage();
  }

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
  next() {
    this.page = this.page + 1;
    this.mathPage();
  }
  prev() {
    this.page = this.page - 1;
    this.mathPage();
  }

  private mathPage() {
    setTimeout(() => {
      const lengthPage = Math.floor(
        this.prevBtn.prevState.value.length / this.prevBtn.prevState.numScroll -
          1
      );
      this.disableNextButton = this.page >= lengthPage || lengthPage === 1;
      this.disablePrevButton = this.page === 0;
    }, 200);
  }
}
