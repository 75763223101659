import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-providers-commits',
  templateUrl: './providers-commits.component.html',
  styleUrls: ['./providers-commits.component.scss']
})
export class ProvidersCommitsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
