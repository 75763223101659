import { Component } from '@angular/core';
import { GaService } from 'src/app/core/pages/services/ga.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  public valueSearch: string;

  constructor(private ga: GaService) {}

  dispatchEvent() {
    this.ga.pushEvent('genericEvent', {
      eventName: 'pesquisar-termo-de-pesquisa',
      parameter1: 'pesquisar',
      parameter2: `${this.valueSearch
        ?.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\s+/g, '-')}`,
      parameter3: '',
      parameter4: '',
      parameter5: '',
    });
    this.valueSearch = null;
  }
}
