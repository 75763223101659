import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-more-btn',
  templateUrl: './view-more-btn.component.html',
  styleUrls: ['./view-more-btn.component.scss'],
})
export class ViewMoreBtnComponent {
  constructor() {}
}
