<div class="address">
  <div class="address__header">
    <h3>Endereços</h3>
    <p>Todos os campos marcados com * são obrigatórios</p>
  </div>
  <form class="address__form" [formGroup]="addressForm">
    <div class="address__form-inputs">
      <input type="text" placeholder="CEP *" formControlName="cep" />
      <input type="text" placeholder="Endereço*" formControlName="address" />
      <div class="two-inputs">
        <input type="number" placeholder="Numero*" formControlName="number" />
        <input
          type="text"
          placeholder="Complemento*"
          formControlName="number"
        />
      </div>
      <input
        type="text"
        placeholder="Bairro *"
        formControlName="neighborhood"
      />
      <div class="two-inputs">
        <input type="text" placeholder="Cidade*" formControlName="city" />
        <input type="text" placeholder="Estado*" formControlName="state" />
      </div>
    </div>
    <div class="buttons">
      <button class="save-information">Salvar informações</button>
      <button class="cancel">Cancelar</button>
    </div>
  </form>
</div>
