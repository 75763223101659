<footer>
  <div class="content">
    <a href="#" class="content__upper">
      <img src="assets/img/logo.svg" alt="logo" />
    </a>

    <div class="content__middle">
      <div class="contact">
        <div class="contact__field">
          <strong>Contato</strong>
          <a href="mailto:contato@borafestejar.com.br"
            >borafestejar@borafestejar.com.br</a
          >
          (11) 93220-8990
        </div>
        <div class="contact__field">
          <strong>Endereço</strong>
          Rua Garcia de Toledo, 1105 Jardim São Bento Novo, São Paulo CEP
          05882-440
        </div>
      </div>

      <ul class="menu">
        <li><a href="#" (click)="dispatchEvent('inicio', true)">Inicio</a></li>
        <li>
          <a href="/sobre-nos" (click)="dispatchEvent('sobre-nos', true)"
            >Sobre nós</a
          >
        </li>
        <li>
          <a href="/servicos" (click)="dispatchEvent('servicos', true)"
            >Serviços</a
          >
        </li>
        <li>
          <a href="/meu-perfil" (click)="dispatchEvent('minha-conta', true)"
            >Minha conta</a
          >
        </li>
      </ul>
      <ul class="whatsapp"></ul>
      <ul class="policies">
        <li>
          <a
            class="wpp-btn"
            href="https://api.whatsapp.com/send?phone=5511932208990"
            (click)="dispatchEvent('fale-com-a-gente-no-whatsapp', false)"
            >Fale com a gente no Whatsapp!</a
          >
        </li>
        <li>
          <a
            href="assets/pdf/bora-festejar_politica-de-privacidade.pdf"
            target="_blank"
            >Política de privacidade</a
          >
        </li>
        <li>
          <a
            href="assets/pdf/bora-festejar_termos-de-uso-e-condicoes.pdf"
            target="_blank"
            >Termos de uso</a
          >
        </li>
      </ul>
    </div>

    <hr color="black" size="1px" />

    <div class="content__bottom">
      <strong>© 2023</strong>
      <span class="separator"></span>
      <a href="www.borafestejar.com.br">www.borafestejar.com.br</a>
      <span class="separator"></span>
      <strong>Todos os direitos reservados</strong>
      <span class="separator"></span>
      <div class="cnpj">CNPJ: 24.057.514/0001-41</div>
    </div>
  </div>
</footer>
