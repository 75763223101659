import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  public changePassForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.changePassForm = this.fb.group({
      currentPass: ['', Validators.compose([Validators.required])],
      newPass: ['', Validators.compose([Validators.required])],
      confirmPass: ['', Validators.compose([Validators.required])],
    });
  }
}
