import { Injectable } from '@angular/core';
import { IUser } from './IUser';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API = environment.apiUrl;
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private httpClient: HttpClient) {}

  register(user: IUser): Observable<any> {
    return this.httpClient.post<IUser>(`${API}users/signup`, user);
  }

  login(user: IUser): Observable<any> {
    return this.httpClient.post<IUser>(`${API}auth/login`, user);
  }
}
