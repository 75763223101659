import { ICategory } from './../../../../../shared/interfaces/ICategories';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoriesService } from 'src/app/shared/services/categories.service';
import { ServicesService } from 'src/app/shared/services/services.service';
import { UploadService } from 'src/app/shared/services/upload.service';
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  providers: [MessageService],
})
export class ServicesComponent implements OnInit {
  public serviceForm: FormGroup;
  public hasData = false;
  public hasUploaded = false;
  public isFormShowing = false;
  public uploadMsg = false;
  public uploadedFiles: any;
  public files: any;
  public fileName = '';
  public categories: ICategory[];
  public collection = []
  public userServices: any

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private categoriesService: CategoriesService,
    private servicesService: ServicesService,
    private uploadService: UploadService,
  ) { }

  ngOnInit(): void {
    this.userServices = JSON.parse(sessionStorage.getItem("profile")).service
    this.getCategories();
    this.buildServiceForm();
  }

  // Toggle service form
  showForm() {
    this.isFormShowing = !this.isFormShowing;
  }

  getCategories() {
    this.categoriesService
      .categoriesList()
      .subscribe((categories) => (this.categories = categories));
  }

  buildServiceForm() {
    this.serviceForm = this.fb.group({
      category: ['', Validators.compose([Validators.required])],
      name: ['', Validators.compose([Validators.required])],
      phone: ['', Validators.compose([Validators.required])],
      website: ['', Validators.compose([Validators.required]),],
      description: ['', Validators.compose([Validators.required])],
    });
  }

  onUpload(event) {

    this.files = event.files;

    const formData = new FormData();

    for (let i = 0; i < event.files.length; i++) {
      formData.append(`img${i}`, event.files[i]);
    }

    this.uploadService.uploadFiles(formData, 'image').subscribe({
      next: (res: any) => {
        this.uploadedFiles = res.map((element, i) => {
          return { url: element, isDefault: i == 0 };
        });
        this.hasUploaded = true;
      },
    });

    let shiftObject = this.files.shift();

    this.collection.push(shiftObject)

    this.messageService.add({
      severity: 'info',
      summary: 'Success',
      detail: 'Arquivo carregado com sucesso.',
    });
  }

  submit() {
    if (this.serviceForm.valid && this.hasUploaded) {
      const payload = {
        department: this.serviceForm.get('category')?.value,
        name: this.serviceForm.get('name')?.value,
        phone: this.serviceForm.get('phone')?.value?.replace(/\D/g, ''),
        website: this.serviceForm.get('website')?.value,
        description: this.serviceForm.get('description')?.value,
        serviceMedia: this.uploadedFiles,
      };
      this.servicesService.submit(payload).subscribe({
        next: () => {
          this.serviceForm.reset();
          this.showSuccess();
          this.hasData = true;
        },
        error: (err) => {
          this.showError();
          this.serviceForm.reset();
          console.error(err);
        },
      });
    }
  }

  // ToastConfigs
  showError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Erro',
      detail: 'Problema no servidor, tente mais tarde.',
    });
  }

  showSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Mensagem enviada!',
    });
  }
}
