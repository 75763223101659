import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProvidersComponent } from './providers.component';
import { ProvidersCardsComponent } from './components/providers-cards/providers-cards.component';
import { CardsComponent } from './components/providers-cards/cards/cards.component';
import { SupplierContactComponent } from './components/supplier-contact/supplier-contact.component';
import { SupplierHeaderComponent } from './components/supplier-header/supplier-header.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProvidersRoutingModule } from './providers-routing.module';
import { FormsModule } from '@angular/forms';
import { ProvidersCommitsComponent } from './components/providers-commits/providers-commits.component';
import { ProviderBoxCommitComponent } from './components/providers-commits/provider-box-commit/provider-box-commit.component';

@NgModule({
  declarations: [
    ProvidersComponent,
    ProvidersCardsComponent,
    CardsComponent,
    SupplierContactComponent,
    SupplierHeaderComponent,
    ProvidersCommitsComponent,
    ProviderBoxCommitComponent,
  ],
  imports: [CommonModule, SharedModule, ProvidersRoutingModule, FormsModule],
  exports: [ProviderBoxCommitComponent],
})
export class ProvidersModule {}
