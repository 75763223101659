<div class="terms-service">
  <h1 class="terms-service__title">Perfil Fornecedor</h1>
  <hr />
  <div class="terms-service__content">
    <p class="terms-service__content-text">
      Além de contratar, você também fornece serviços ?
    </p>
    <p class="terms-service__content-text">
      Mude sua conta para tipo Fornecedor!
    </p>
    <p class="terms-service__content-text">
      A conta de Fornecedor permite contratar e oferecer serviços na plataforma.
    </p>
    <p class="terms-service__content-text">
      Ao mudar para conta de Fornecedor, você declara que leu e aceitou os
      termos de uso e a
      <a
        class="terms-service__content-text-link"
        href="assets/pdf/bora-festejar_politica-de-privacidade.pdf"
        target="_blank"
        >Política de Privacidade</a
      >
      do site.
    </p>
  </div>
  <div class="buttons">
    <button class="buttons__cancel" (click)="closeDialog(true)">
      Cancelar
    </button>
    <button class="buttons__change" (click)="closeDialog(true)">
      Mudar agora
    </button>
  </div>
</div>
