<section class="comments">
  <div class="comments__title">
    <span></span>
    <h1>Comentários</h1>
  </div>
  <div class="comments__main">
    <div class="filters">
      <h2>Filtros</h2>
      <ul>
        <li><a href="#">Maiores Notas</a></li>
        <li><a href="#">Menores Notas</a></li>
        <li><a href="#">Mais Recente</a></li>
        <li><a href="#">Mais Antigo</a></li>
      </ul>
    </div>
    <div class="cards">
      <app-provider-box-commit></app-provider-box-commit>
      <app-provider-box-commit></app-provider-box-commit>
      <app-provider-box-commit></app-provider-box-commit>
    </div>
  </div>
</section>
