<div class="card">
  <div class="card__header">
    <div class="author">
      <img
        src="assets/img/suppliers/comment-author.png"
        alt="foto do usuário"
      />
      <div class="info">
        <div class="info__name">
          <strong>Nome do autor</strong>
          <p>dd/mm/aaaa 00:00</p>
        </div>

        <div class="rating">
          <div class="number">5/5</div>
          <div class="stars">
            <img
              src="assets/img/icons/supplier/comments/star.svg"
              alt=""
              *ngFor="let _ of [].constructor(5)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card__main">
    Comentario Comentario Comentario Comentario Comentario Comentario Comentario
    Comentario Comentario Comentario Comentario Comentario Comentario Comentario
    Comentario Comentario Comentario Comentario Comentario Comentario Comentario
    Comentario Comentario Comentario Comentario Comentario Comentario
  </div>
</div>
