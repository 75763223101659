<div class="personal-data">
  <div class="personal-data__header">
    <h3>Dados Pessoais</h3>
    <p>Todos os campos marcados com * são obrigatórios</p>
  </div>

  <div class="scope-web">
    <form [formGroup]="registerDataForm" (ngSubmit)="submitUserData()">
      <div class="inputs">
        <input
          [ngStyle]="{ color: hasData ? '#495057' : '#000000' }"
          [readonly]="hasData"
          formControlName="name"
          type="text"
          name="nome"
          placeholder="Nome completo *"
        />
        <p-inputMask
          [readonly]="hasData"
          mask="99/99/9999"
          styleClass="input-mask"
          type="text"
          formControlName="bornDate"
          placeholder="Data de nascimento *"
          slotChar="  /  /    "
        ></p-inputMask>
        <p-inputMask
          [readonly]="hasData"
          styleClass="input-mask"
          formControlName="cellphone"
          placeholder="Celular *"
          mask="(99) 9 9999-9999"
        >
        </p-inputMask>
        <p-inputMask
          [readonly]="hasData"
          styleClass="input-mask"
          formControlName="email"
          placeholder="E-mail *"
          type="email"
        >
        </p-inputMask>
      </div>
      <div class="buttons">
        <button
          [disabled]="registerDataForm.invalid || hasData"
          type="submit"
          class="save-information"
        >
          Salvar informações
        </button>
        <button
          *ngIf="!hasData"
          (click)="registerDataForm.reset()"
          class="cancel"
        >
          CANCELAR
        </button>
      </div>
    </form>
    <div class="load-img">
      <div class="load-img-content">
        <img src="assets/img/icons/profile/user.svg" alt="User" />
        <a href="#" class="load-img__btn-load">
          <img src="assets/img/icons/profile/image.svg" alt="" />
          Carregar imagem
        </a>
        <a href="#">Remover</a>
      </div>
    </div>
  </div>
</div>
<p-toast position="top-right"></p-toast>
