import { Router } from '@angular/router';
import { ELocalStorage } from 'src/app/core/auth/authenticate/enums/localstorage.num';
import { AuthService } from './core/auth/authenticate/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  withHeader = true;
  withFooter = true;
  currentPath: string;
  constructor(
    private auth: AuthService,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.checkSession();
    this.router.events.subscribe(() => {
      const location: string = this.location.path();
      if (
        location === '/login' ||
        location === '/login/criar-conta' ||
        location === '/login/esqueci-a-senha' ||
        location === '/login/alterar-senha' ||
        location === '/login/email-enviado'
      ) {
        this.withHeader = false;
        this.withFooter = false;
      } else if (
        location === '/meu-perfil' ||
        location === '/meu-perfil/endereco' ||
        location === '/meu-perfil/documentos' ||
        location === '/meu-perfil/mudar-senha' ||
        location === '/meu-perfil/servicos'
      ) {
        this.withHeader = true;
        this.withFooter = false;
      } else if (location === '') {
        this.withHeader = true;
        this.withFooter = true;
      }
    });
  }

  checkSession() {
    let itemStr = sessionStorage.getItem(ELocalStorage.USER_TOKEN);

    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry || !item.expiry) {
      sessionStorage.removeItem(ELocalStorage.USER_TOKEN);

      return null;
    }
  }
}
