<div class="cards-menu-carousel">
  <div class="carousel-buttons">
    <button
      class="carousel-buttons-btn"
      (click)="prev()"
      [disabled]="disablePrevButton"
    >
      <img
        class="btn-prev"
        src="assets/img/icons/profile/arrow-down.svg"
        alt=""
      />
    </button>
    <button
      class="carousel-buttons-btn"
      (click)="next()"
      [disabled]="disableNextButton"
    >
      <img src="assets/img/icons/profile/arrow-down.svg" alt="" />
    </button>
  </div>
  <p-carousel
    #carousel
    (click)="dispatchEvent.emit()"
    [value]="dataCarousel"
    [numVisible]="7"
    [numScroll]="1"
    [responsiveOptions]="options"
    [showIndicators]="false"
    [page]="page"
    [showNavigators]="false"
  >
    <ng-template let-card pTemplate="item">
      <app-categorie [categoryInfo]="card"></app-categorie>
    </ng-template>
  </p-carousel>
</div>
