<header>
  <div class="brand-content">
    <a href="/" class="brand-content__logo">
      <img src="assets/img/logo.svg" alt="logo" />
    </a>
    <a class="mobile-menu" *ngIf="!sidebar" (click)="toggleSidebar()">
      <img src="assets/img/icons/mobile-menu.svg" />
    </a>
    <a class="mobile-menu" *ngIf="sidebar" (click)="toggleSidebar()">
      <img src="assets/img/icons/x.svg" />
    </a>
  </div>

  <nav class="navbar">
    <ul class="navbar__list">
      <li class="navbar__list-item">
        <a
          (click)="
            dispatchEvent('/', {
              eventName: 'home-inicio',
              parameter1: 'home',
              parameter2: 'inicio'
            })
          "
          >Início</a
        >
      </li>
      <li class="navbar__list-item">
        <a
          (click)="
            dispatchEvent('sobre-nos', {
              eventName: 'home-sobre',
              parameter1: 'home',
              parameter2: 'sobre'
            })
          "
          >Sobre nós</a
        >
      </li>
      <li class="navbar__list-item">
        <a
          (click)="
            dispatchEvent('servicos', {
              eventName: 'home-servicos',
              parameter1: 'home',
              parameter2: 'servicos'
            })
          "
          >Serviços</a
        >
      </li>

      <span *ngIf="!isAuthenticated; else logged">
        <li class="navbar__login">
          <a href="/login" (click)="dispatchEvent('login')">Entrar</a>
        </li>
        <li class="navbar__new-account">
          <a
            href="/login/criar-conta"
            (click)="dispatchEvent('login-criar-conta')"
            >Criar conta</a
          >
        </li>
      </span>

      <ng-template #logged>
        <span>
          <li class="navbar__list-item">
            <a href="/meu-perfil">Minha conta</a>
          </li>
        </span>
        <div class="menu-profile">
          <img
            class="menu-profile-avatar"
            href=""
            [src]="
              userData?.profilePhoto || 'assets/img/avatar-placeholder.PNG'
            "
            alt="Profile photo"
          />
          <div
            class="menu-profile__name"
            (click)="toggleMenu()"
            style="cursor: pointer"
          >
            {{ userData?.name?.split(" ")[0] || "Sem nome" }}
            <img src="assets/img/icons/profile/arrow-down.svg" alt="" />
          </div>
          <div
            *ngIf="dropdownOpen"
            class="dropdown-menu"
            style="cursor: pointer"
          >
            <a href="/meu-perfil" (click)="dispatchEvent('meu-perfil')"
              >Meu Perfil</a
            >
            <a (click)="logout()">Sair</a>
          </div>
        </div>
      </ng-template>
    </ul>
  </nav>
</header>

<aside *ngIf="sidebar" [@enterAnimation]>
  <nav>
    <ul>
      <li><a (click)="
        dispatchEvent('/', {
          eventName: 'home-inicio',
            parameter1: 'home',
            parameter2: 'inicio'
        })
      ">Inicio</a></li>
      <li><a (click)="
        dispatchEvent('sobre-nos', {
          eventName: 'home-sobre',
          parameter1: 'home',
          parameter2: 'sobre'
        })
      ">Sobre nós</a></li>
      <li><a (click)="
        dispatchEvent('servicos', {
          eventName: 'home-servicos',
          parameter1: 'home',
          parameter2: 'servicos'
        })
      ">Serviços</a></li>
      <ng-container *ngIf="!isAuthenticated; else logged">
        <li><a class="button" (click)="
          dispatchEvent('login', {
            eventName: 'home-login',
            parameter1: 'home',
            parameter2: 'login'
          })
        ">Entrar</a></li>
        <li><a class="button" (click)="
          dispatchEvent('login/criar-conta', {
            eventName: 'home-criar-conta',
            parameter1: 'home',
            parameter2: 'criar',
            parameter3: 'conta'
          })
        ">Criar conta</a></li>
      </ng-container>
      <ng-template #logged>
        <li><a (click)="
          dispatchEvent('minha-conta', {
            eventName: 'minha-conta',
            parameter1: 'minha',
            parameter2: 'conta'
          })
        ">Minha conta</a></li>
        <li><a (click)="logout()">Sair</a></li>
      </ng-template>
    </ul>
  </nav>
</aside>
