<p-toast position="top-right"></p-toast>
<div class="content">
  <div class="content__contact">
    <div class="content__contact-title">
      <span class="orange-square"></span>
      <h1>Contato</h1>
    </div>
    <div class="panel">
      <div class="links">
        <a href="">
          <img src="assets/img/icons/supplier/localization.svg" />
          <span>{{
            providerData?.address ? providerData?.address : "Sem endereço"
          }}</span>
        </a>
        <a href="">
          <img src="assets/img/icons/supplier/instagram.svg" />
          <span>{{
            providerData?.socialMedia?.instagram
              ? providerData?.socialMedia?.instagram
              : "Sem instagram"
          }}</span>
        </a>
        <a href="">
          <img src="assets/img/icons/supplier/facebook.svg" />
          <span>{{
            providerData?.socialMedia?.facebook
              ? providerData?.socialMedia?.facebook
              : "Sem facebook"
          }}</span>
        </a>
        <a [href]="'http://' + providerData?.website" target="_blank">
          <img src="assets/img/icons/supplier/mouse-arrow.svg" />
          <span>{{ providerData?.website }}</span>
        </a>
      </div>
    </div>
  </div>
  <div class="content__description">
    <div class="title">
      <span class="orange-square"></span>
      <h1>Descrição</h1>
    </div>
    <p class="content__description-text">
      {{ providerData?.description }}
    </p>
  </div>
  <div class="content__form">
    <form [formGroup]="buildFormContact" (ngSubmit)="sendContact()">
      <input formControlName="name" type="text" placeholder="Nome*" />
      <input formControlName="email" type="email" placeholder="E-mail*" />
      <textarea formControlName="message" placeholder="Mensagem"></textarea>
      <button type="submit" [disabled]="buildFormContact.invalid">
        ENVIAR MENSAGEM
      </button>
    </form>
  </div>
</div>
<div class="comments">
  <div class="comments__title">
    <span></span>
    <h1 class="comments__title-text">Comentários</h1>
  </div>
  <div class="comments__content">
    <div class="comments__content-filters">
      <h2 class="comments__content-filters--title">Filtros</h2>
      <ul class="comments__content-filters--list">
        <li class="comments__content-filters--list--item">Maiores notas</li>
        <li class="comments__content-filters--list--item">Menores notas</li>
        <li class="comments__content-filters--list--item">Mais recente</li>
        <li class="comments__content-filters--list--item">Mais antigo</li>
      </ul>
    </div>
    <div class="provider-box">
      <app-provider-box-commit></app-provider-box-commit>
      <app-provider-box-commit></app-provider-box-commit>
      <app-provider-box-commit></app-provider-box-commit>
      <app-view-more-btn></app-view-more-btn>
    </div>
  </div>
</div>
