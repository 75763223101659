import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-event-provider',
  templateUrl: './event-provider.component.html',
  styleUrls: ['./event-provider.component.scss'],
})
export class EventProviderComponent implements OnInit {
  @Input() eventInfo: any;

  public imageDefault: string;

  ngOnInit(): void {
    this.getImageDefault();
  }

  getImageDefault() {
    this.imageDefault = this.eventInfo?.serviceMedia?.find((element: any) => {
      return !!element.isDefault;
    })?.url;
  }
}
