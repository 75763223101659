import { FileUploadModule } from 'primeng/fileupload';
import { MatDialogModule } from '@angular/material/dialog';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'primeng/carousel';
import { PaginatorModule } from 'primeng/paginator';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SearchComponent } from './search/search.component';
import { EventProviderComponent } from './event-provider/event-provider.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { AccordionComponent } from './accordion/accordion.component';
import { TermsServicesComponent } from '../core/pages/components/terms-services/terms-services.component';

// Lib NgPrime
import { ToastModule } from 'primeng/toast';
import { InputMaskModule } from 'primeng/inputmask';
import { NgxPaginationModule } from 'ngx-pagination';
import { CarouselComponent } from './carousel/carousel.component';
import { ViewMoreBtnComponent } from './view-more-btn/view-more-btn.component';
@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    SearchComponent,
    EventProviderComponent,
    ErrorMessageComponent,
    CarouselComponent,
    AccordionComponent,
    TermsServicesComponent,
    ViewMoreBtnComponent,
  ],
  imports: [
    CommonModule,
    CarouselModule,
    PaginatorModule,
    MatDialogModule,
    ReactiveFormsModule,
    ToastModule,
    InputMaskModule,
    FileUploadModule,
    NgxPaginationModule,
    FormsModule,
  ],
  exports: [
    EventProviderComponent,
    FooterComponent,
    SearchComponent,
    HeaderComponent,
    CarouselModule,
    PaginatorModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    ToastModule,
    InputMaskModule,
    FileUploadModule,
    NgxPaginationModule,
    CarouselComponent,
    AccordionComponent,
    TermsServicesComponent,
    ViewMoreBtnComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
