<div>
  <dl class="accordion">
    <dt
      class="accordion__header no-select"
      [ngClass]="{ open: isOpen }"
      (click)="isOpen = !isOpen"
    >
      <h2>{{ title }}</h2>
      <div class="accordion__header-arrow">
        <img
          [ngClass]="{ open: isOpen }"
          src="assets/img/icons/profile/chevron-down.svg"
          alt=""
        />
      </div>
    </dt>
    <dd
      class="accordion__content"
      *ngIf="isOpen"
      [ngClass]="{ open: isOpen }"
      [@openAnimation]
    >
      <ng-content select="[selector]"></ng-content>
    </dd>
  </dl>
</div>
