import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IContact } from '../contact-form.model';
import { environment } from 'src/environments/environment';

const API = environment.apiUrl;
@Injectable({
  providedIn: 'root',
})
export class SendContactService {

  constructor(private http: HttpClient) { }

  sendContact(contact: IContact | any): Observable<any> {
    return this.http.post<IContact>(`${API}contact`, contact);
  }

}
