import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { SendContactService } from '../../../home/components/contact-form/services/send-contact.service';
import { GaService } from '../../../services/ga.service';

@Component({
  selector: 'app-supplier-contact',
  templateUrl: './supplier-contact.component.html',
  styleUrls: ['./supplier-contact.component.scss'],
  providers: [MessageService],
})
export class SupplierContactComponent implements OnInit {
  buildFormContact: FormGroup;

  @Input() providerData: any;

  constructor(
    private fb: FormBuilder,
    private contact: SendContactService,
    private messageService: MessageService,
    private ga: GaService
  ) {}

  ngOnInit(): void {
    this.initFormContact();
  }

  initFormContact() {
    this.buildFormContact = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      message: ['', Validators.compose([Validators.required])],
    });
  }

  sendContact() {
    if (this.buildFormContact.valid) {
      const payloadContact = {
        name: this.buildFormContact.get('name')?.value,
        email: this.buildFormContact.get('email')?.value,
        message: this.buildFormContact.get('message')?.value,
      };
      const payloadGa = [
        {
          eventName: 'fornecedor-nome',
          parameter2: payloadContact.name,
        },
        {
          eventName: 'fornecedor-email',
          parameter2: payloadContact.email,
        },
        {
          eventName: 'fornecedor-mensagem',
          parameter2: payloadContact.message,
        },
        {
          eventName: 'fornecedor-enviar-mensagem',
          parameter2: 'enviar-mensagem',
        },
      ];
      this.contact.sendContact(payloadContact).subscribe({
        next: (_) => {
          payloadGa.forEach((p) => {
            this.ga.pushEvent(
              'genericEvent',
              Object.assign(
                {
                  eventName: '',
                  parameter1: 'lead-fornecedor',
                  parameter2: '',
                  parameter3: '',
                  parameter4: '',
                  parameter5: '',
                },
                p
              )
            );
          });
          this.showSuccess();
          this.buildFormContact.reset();
        },
        error: (err) => {
          this.showError();
          console.error(err);
        },
      });
    }
  }

  // ToastConfigs
  showError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Erro',
      detail: 'Problema no servidor, tente mais tarde.',
    });
  }

  showSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Mensagem enviada!',
    });
  }
}
