import { DialogComponent } from './components/services/dialog/dialog.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileRoutingModule } from './user-profile-routing.module';
import { UserProfileComponent } from './user-profile.component';
import { PersonalDataComponent } from './components/personal-data/personal-data.component';
import { AddressComponent } from './components/address/address.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ServicesComponent } from './components/services/services.component';
@NgModule({
  declarations: [
    UserProfileComponent,
    PersonalDataComponent,
    AddressComponent,
    DocumentsComponent,
    ChangePasswordComponent,
    ServicesComponent,
    DialogComponent,
  ],
  imports: [CommonModule, UserProfileRoutingModule, FormsModule, SharedModule],
})
export class UserProfileModule {}
