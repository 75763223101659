<div class="personal-data">
  <div class="personal-data__menu">
    <a
      href="/meu-perfil"
      routerLink="/meu-perfil"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      Dados pessoais
    </a>
    <a id="disabled" routerLinkActive="disabled-link">Endereços</a>
    <a id="disabled" routerLinkActive="disabled-link">Documentos</a>
    <a id="disabled" routerLinkActive="disabled-link">Mudar Senha</a>
    <a
      routerLinkActive="active-link"
      routerLink="/meu-perfil/servicos"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="dispatchEvent()">
      Serviços
    </a>
  </div>

  <div class="personal-data-component">
    <router-outlet></router-outlet>
  </div>

  <div class="accordion-scope">
    <app-accordion class="accordion" title="Dados pessoais" [isOpen]="true">
      <ng-container selector>
        <app-personal-data></app-personal-data>
      </ng-container>
    </app-accordion>

    <app-accordion class="accordion" title="Endereços" [isOpen]="false">
      <ng-container selector>
        <app-address></app-address>
      </ng-container>
    </app-accordion>
    <app-accordion class="accordion" title="Documentos" [isOpen]="false">
      <ng-container selector>
        <app-documents></app-documents>
      </ng-container>
    </app-accordion>
    <app-accordion class="accordion" title="Mudar Senha" [isOpen]="false">
      <ng-container selector>
        <app-change-password></app-change-password>
      </ng-container>
    </app-accordion>
    <app-accordion class="accordion" title="Serviços" [isOpen]="false">
      <ng-container selector>
        <app-terms-services></app-terms-services>
      </ng-container>
    </app-accordion>
  </div>
</div>
